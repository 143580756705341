import type { LocationQuery, RouteRecordName } from 'vue-router';

export const keepQuery = (
  path: RouteRecordName
): { name: RouteRecordName; query: LocationQuery } => {
  return {
    name: path,
    query: useRoute().query,
  };
};
